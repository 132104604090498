
import { writable } from 'svelte/store'
import { persisted } from 'svelte-local-storage-store'


export const isLoading = writable(false);
export const loadingMessage = writable('Loading, please wait...');
export const user = writable({loggedin: false, email: ""});
export const userAccount = writable({email: "", password: ""});
export const code_verifier = persisted('verifier', '');
